<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help lang="fr" [file]="pathFile" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          (onClick)="openDialogEdition()"
          tooltip="Créer un repas">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <yo-button
      (onClick)="exportXslx()"
      iconClass="fas fa-download"
      class="mg-r-5"
      tooltip="Exporter les éléments format Excel">
    </yo-button>
    <yo-button
      iconClass="fas fa-upload"
      class="mg-r-5"
      (onClick)="opImportXls.toggle($event)"
      tooltip="Importer les éléments à partir d'une feuille Excel">
    </yo-button>
    <yo-button [disabled]="!canDelete()" buttonClass="cta-delete" (onClick)="deleteValues()"
               tooltip="Supprimer les éléments sélectionnés" iconClass="fas fa-trash"></yo-button>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="repas"
  keyExpr="id"
  class="data-grid"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  columnResizingMode="widget"
  #grid>
  <dxo-selection
    selectAllMode="allPages"
    showCheckBoxesMode="always"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions"
              [width]="79"
              cssClass="text-align-center vertical-align-middle mg-r-0"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" caption="Site"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="siteTemplate"
              [calculateCellValue]="calculateSiteValue">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Code"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="ordre" caption="Ordre"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="actif" caption="Actif"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openDialogEdition(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="repasSvc.canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="repasSvc.canModify(cell.row.data)?'Modifier le repas ':'Voir le repas'">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'siteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>


</dx-data-grid>

<p-overlayPanel #opImportXls>
  <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
         (change)="importXslx($event)">
</p-overlayPanel>

<dx-popup
  [showTitle]="true"
  title="Lignes n'ayant pas été supprimées"
  [(visible)]="displayDeletePopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Les éléments non présents dans cette liste ont bien été supprimés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="infosLine" caption="Ligne non supprimée" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>

<dx-popup
  [showTitle]="true"
  title="Lignes n'ayant pas été importées"
  [(visible)]="displayImportPopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Les éléments non présents dans cette liste ont bien été importés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="infosLine" caption="Ligne non importée" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxi-column dataField="labelsError" caption="Erreur(s)" [allowFiltering]="false"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>
<yo-meal-edition-dialog></yo-meal-edition-dialog>
