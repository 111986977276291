import {Component, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {AvoirFournisseurDto} from "../../../../core/dtos/facturation/avoirs/avoir-fournisseur-dto";
import {AvoirService} from "../../../../core/services/entities/avoir.service";
import {AvoirFournisseurLigneDto} from "../../../../core/dtos/facturation/avoirs/avoir-fournisseur-ligne-dto";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {PrixDto} from "../../../../core/dtos/prix-dto";
import {QuantiteDto} from "../../../../core/dtos/quantite-dto";
import {NavigationService} from "../../../../core/services/entities/navigation.service";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-avoirFournisseurDetailsDialog',
  templateUrl: './avoir-fournisseur-details-dialog.component.html',
  styleUrls: ['./avoir-fournisseur-details-dialog.component.scss']
})
export class AvoirFournisseurDetailsDialogComponent implements OnInit {

  private subOpenDialog: Subscription

  public avoir: AvoirFournisseurDto = new AvoirFournisseurDto();

  public displayDialog: boolean = false;

  @ViewChild("grid") grid: DxDataGridComponent;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              public avoirSvc: AvoirService,
              public utilsSvc: UtilsService,
              private i8nSvc: InternationalizationService,
              private navigationSvc: NavigationService) {
  }

  ngOnInit() {
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.avoirSvc.openDetailsDialog$
      .subscribe((avoir: AvoirFournisseurDto) => {
        if (avoir) {
          this.displayDialog = true;
          this.avoir = avoir;
        }
      });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  getMotifsNonConformiteDisplayValue(avoirLigne: AvoirFournisseurLigneDto) {
    return avoirLigne.motifNonConformite.map(motif => motif.libelle).reduce((motif1, motif2) => motif1 + ", " + motif2).toString();
  }

  // ATTENTION ! Laisser cette fonction sour le format nomDeFonction() {...} sinon cela ne fonctionne plus !
  getPrixDisplayValue(data: PrixDto | AvoirFournisseurLigneDto): string {
    if (!data)
      return null;
    if (data.hasOwnProperty("value"))
      return (data as PrixDto).value.toFixed(4) + " " + (data as PrixDto).uniteMonetaire;

    const prix = data[(this as any).dataField];
    return prix.value.toFixed(4) + " " + prix.uniteMonetaire;
  }

  // ATTENTION ! Laisser cette fonction sour le format nomDeFonction() {...} sinon cela ne fonctionne plus !
  getQuantiteDisplayValue(data: QuantiteDto | AvoirFournisseurLigneDto): string {
    if (!data)
      return null;
    if (data instanceof QuantiteDto)
      return +data.value.toFixed(3) + " " + data.uniteDeMesure;

    let quantite = data[(this as any).dataField];
    return +quantite.value.toFixed(3) + " " + quantite.uniteDeMesure.abreviation;
  }

  getStatusDisplayValue = (): string => {
    if (!this.avoir?.statut)
      return null;
    let result = this.i8nSvc.getLabelFromCode("AVOIR_STATUS_" + this.avoir.statut, null);
    if (!result)
      return this.i8nSvc.getLabelFromCode("AVOIR_STATUS_UNKNOWN", null);
    return result;
  };

  navigateToFacture() {
    this.navigationSvc.navigateToFacture(this.avoir.facture.fournisseur.id, this.avoir.facture.numero);
  }

  resizeChilds($event) {
    this.grid.height = $event.height - 230;
  }
}
