import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {UtilsService} from "../../utils/utils.service";
import {AvoirFournisseurDto} from "../../dtos/facturation/avoirs/avoir-fournisseur-dto";

@Injectable({
  providedIn: 'root'
})
export class AvoirService {

  private subjectAvoirGenerated = new Subject<AvoirFournisseurDto>();
  avoirGenerated$ = this.subjectAvoirGenerated.asObservable();

  private subjectOpenDetailsDialog = new Subject<AvoirFournisseurDto>();
  openDetailsDialog$ = this.subjectOpenDetailsDialog.asObservable();

  constructor(private httpSvc: HttpService,
              private utilsSvc: UtilsService) {}

  generateAvoir = (idFacture: number) : Observable<ResponseWrapper<AvoirFournisseurDto>> => {
    const url = `dolrest/avoirs/generate?idFacture=${idFacture}`;
    return this.httpSvc.post(url, null);
  }

  announceAvoirGenerated = (avoir: AvoirFournisseurDto): void => {
    this.subjectAvoirGenerated.next(avoir);
}

  announceOpenDetailsDialog = (avoir: AvoirFournisseurDto) => {
    this.subjectOpenDetailsDialog.next(avoir);
  };

  deleteAvoirsByFilters = (rowsToDelete: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&idsAvoir=${rowsToDelete.join(',')}`
    return this.httpSvc.delete(`dolrest/avoirs/delete${params}`);
  };
}
