<dx-toolbar class="yo-toolbar" xmlns="http://www.w3.org/1999/html">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-cubes mg-r-5 mg-l-15"></i> INVENTAIRE
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button iconClass="fas fa-print"
                 class="mg-r-5"
                 (onClick)="printInventaireService.announceDisplayDialogPrintInventaire(true)"
                 tooltip="Extraction de l'inventaire">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<div style="scroll-behavior: auto;">
  <dx-accordion
    #accordion
    keyExpr="id"
    [dataSource]="lignesInventaire | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: totalDenrees }"
    [collapsible]="true"
    [multiple]="false"
    id="accordion-container"
  >
    <div *dxTemplate="let rowData of 'title'">
      <div [class]="rowData.noStock ? 'bg-secondary-header no-stock' : 'bg-secondary-header'"
           class="d-flex justify-content-between">
        <div class="col-lg-4 d-flex align-items-center">
          <span class="mg-r-5">
                    <yo-button iconClass="fas fa-plus" buttonClass="cta-secondary"
                               tooltip="Ajout d'un nouveau lot"
                               (onClick)="openDialogAjoutLot(rowData, $event)"></yo-button>
                  </span>
          <span class="mg-r-5 bold"> {{rowData.produitDeclinaisonCode}}
            - {{rowData.produitDeclinaisonLibelle}} <i *ngIf="rowData.noStock"
                                                       class="fas fa-ban danger-color mg-r-5"></i> <span
              *ngIf="rowData.noStock">Pas de stock</span>
                </span>
        </div>
        <div class="col-lg-4" style="display: grid">
          <span>En stock : {{rowData.quantiteUS}} {{rowData.uniteDeStockage}}</span>
          <div class="d-flex align-items-center m-2">
            <label class="m-0">Qté ajustée : </label>
            <input [class]="rowData.noStock ? 'bg-secondary-header no-stock' : 'bg-secondary-header'" type="number"
                   [style.width.px]="75"
                   (click)="onClick($event, rowData)"
                   (ngModelChange)="updateQteLotsInventaire($event, rowData)"
                   class="mg-2 form-control-plaintext" [ngModel]="rowData.quantiteUS">
            <div class="input-group-append">
              <span>{{rowData.uniteDeStockage}}</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4" style="display: grid">
          <span class="floatright"
                *ngIf="rowData.totalHT">TOTAL HT = {{rowData.totalHT | currency:'EUR':'symbol':'1.2-4'}}</span>
          <span class="floatright"
                *ngIf="rowData.totalHT">TOTAL TTC = {{rowData.totalTTC | currency:'EUR':'symbol':'1.2-4'}} </span>
        </div>
      </div>
    </div>

    <div *dxTemplate="let rowData of 'item'">

      <table class="table">
        <thead class="thead-light">
        <tr>
          <th>Lot</th>
          <th>Zone de stockage</th>
          <th>Réceptionné le</th>
          <th>DLC</th>
          <th>U.S</th>
          <th>Qté</th>
          <th>Qté Réaj</th>
        </tr>
        </thead>
        <tbody *ngFor="let child of rowData.children">
        <tr>
          <td style="text-align: left">
            {{child.lotArticle}}
          </td>
          <td style="text-align: left">
            <div>
              <i class="fas fa-industry mg-r-5"></i>{{child.udpLibelle}}
            </div>
            <div class="mg-l-10 mg-t-5">
              <i class="fas fa-cubes mg-r-5"></i>{{child.zdsLibelle}}
            </div>
          </td>
          <td style="text-align: left">
            {{child.dateEntree | date:'dd/MM/yy'}}
          </td>
          <td style="text-align: left">
            {{child.dlc | date:'dd/MM/yy'}}
          </td>
          <td style="text-align: left">
            {{child.uniteDeStockage}}
          </td>
          <td style="text-align: right">
            {{child.quantite  | number:'1.4-4'}}
          </td>
          <td class="m-0" [style.width.px]="200">
            <div class="input-group mb-0 justify-content-end">
              <input [style.width.px]="75"
                     class="text-right"
                     type="number"
                     min="0"
                     (ngModelChange)="updateQuantityReaj($event, child)"
                     (keydown)="utils.keydownNumber($event, child,'quantiteReaj',true)"
                     [ngModel]="child.quantiteReaj">
              <div class="input-group-append">
                <span class="m-0 input-group-text" id="basic-addon2">{{child.uniteDeStockage}}</span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </dx-accordion>

  <pagination-controls
    class="text-center p-4"
    (pageChange)="handlePageChange($event)"
    [directionLinks]="false"
    autoHide="true"
    responsive="true"
    previousLabel="Précédent"
    nextLabel="Suivant"></pagination-controls>
</div>

<yo-dialog-lot-ajout></yo-dialog-lot-ajout>
<yo-dialog-print-inventaire [searchSupplierWrapper]="ssw"></yo-dialog-print-inventaire>
