import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {FormGroup} from '@angular/forms';
import {CatalogueAchatDTO} from '../../../../../core/dtos/catalogue-achat-dto';
import {UniteDeProductionDTO} from '../../../../../core/dtos/unite-de-production-dto';
import {ProduitDTO} from '../../../../../core/dtos/produit-dto';
import {
  CatalogueAchatDialogSupplier,
  CataloguesAchatsService
} from '../../../../../core/services/entities/catalogues-achats.service';
import {FormFieldBaseSupplier} from '../../../../../core/suppliers/form-fieldbase-supplier';
import {FormOptions} from '../../../../../core/suppliers/form-options';
import {of, Subscription} from 'rxjs';
import {GROUP_POSITION, GroupFormFields} from '../../../../../core/suppliers/group-formfields';
import {switchMap} from 'rxjs/operators';
import {cloneDeep as _cloneDeep} from 'lodash';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import {GenericFormService} from '../../../../../core/services/generics/generic-form.service';
import {ProduitDeclinaisonDTO} from '../../../../../core/dtos/produit-declinaison-dto';
import {UniteDeMesureDTO} from '../../../../../core/dtos/unitedemesure-dto';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../../../core/models/is-deletable-object";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {ActivatedRoute} from "@angular/router";
import {ProduitDeclinaisonService} from "../../../../../core/services/entities/produit-declinaison.service";

@Component({
  selector: 'yo-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent implements OnInit, OnDestroy {


  groups: GroupFormFields[] = [];

  catalogueAchat: CatalogueAchatDTO;
  cads: CatalogueAchatDialogSupplier;
  fields: FormFieldBaseSupplier<any>[];
  formOptions: FormOptions = {width: 980};

  form: FormGroup;
  formFournisseur: FormGroup;
  formArticle: FormGroup;
  formFacturation: FormGroup;
  formCommande: FormGroup;
  formStock: FormGroup;
  uniteDeProduction: UniteDeProductionDTO;
  produit: ProduitDTO;

  oldPrice: number;
  isCreateMode: boolean;


  subArticleInfos: Subscription;
  subDeletedId: Subscription;

  nbPlatsUsedCurrentProduit: number;

  constructor(public utils: UtilsService,
              private gds: GenericDatagridService,
              private gfs: GenericFormService,
              public auth2Svc: Auth2Service,
              public catalogueAchatSvc: CataloguesAchatsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    // initialiser les champs du formulaire
    this.initArticleFormSubscription();
    // fermer le dialog lors d'une suppression d'article
    this.deletedIdSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subArticleInfos);
    this.utils.unsubscribe(this.subDeletedId);
  }

  deletedIdSubscription = () => {
    this.subDeletedId = this.gfs.deletedId$.subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.catalogueAchatSvc.announceCloseDialogArticle(null);
      }
    });
  };

  initArticleFormSubscription = () => {

    this.subArticleInfos = this.catalogueAchatSvc.articleInfos$
      .pipe(
        switchMap(data => {
          if (!this.utils.isNullOrEmpty(data)) {
            this.cads = data;
            console.log('cads ', this.cads);
            this.catalogueAchat = _cloneDeep(data.catalogueAchat);
            this.produit = _cloneDeep(data.produit);
            this.uniteDeProduction = _cloneDeep(data.uniteDeProduction);
            this.initForm(this.catalogueAchat, this.produit, this.uniteDeProduction);
          }
          return of(null);
        })
      )
      .subscribe();
  };

  canModifyForUpdate = (): boolean => this.catalogueAchat.site && this.catalogueAchat.site.id && this.auth2Svc.isSiteLocal(this.catalogueAchat.site.id) && !this.catalogueAchat.caParentId;

  initForm = (catalogueAchat: CatalogueAchatDTO, produit: ProduitDTO, uniteDeProduction: UniteDeProductionDTO) => {

    this.catalogueAchatSvc.prepareFields(catalogueAchat, produit, uniteDeProduction).subscribe(response => {

      this.catalogueAchat = response.catalogueAchat;
      this.oldPrice = this.catalogueAchat.prix;
      this.isCreateMode = this.catalogueAchat.id === 0;

      this.fields = response.fields;

      const fieldsFournisseurKey = ['fournisseur'];
      const fieldsFournisseur = this.gfs.getFieldsByKey(fieldsFournisseurKey, this.fields);
      this.formFournisseur = this.gfs.toFormGroup(fieldsFournisseur);

      const fieldsArticle = this.gfs.getFieldsByKey(['produitArticleLibelle', 'produitDeclinaison', 'codeApi', 'referenceArticle', 'produitCarne', 'actif', 'ratioUniteStockageUniteTechnique'], this.fields);
      this.formArticle = this.gfs.toFormGroup(fieldsArticle);

      const fieldsFacturation = this.gfs.getFieldsByKey(['uniteDeFacturation', 'ratioUniteFacturationUniteBase', 'ratioUniteCommandeUniteFacturation', this.getKeyFormPrice(catalogueAchat)], this.fields);
      this.formFacturation = this.gfs.toFormGroup(fieldsFacturation);

      const fieldsCommande = this.gfs.getFieldsByKey(['uniteDeCommande', 'ratioUniteCommandeUniteFacturation', 'ratioUniteCommandeUniteBase', 'minimumDeCommande', 'ratioUniteStockageUniteTechnique'], this.fields);
      this.formCommande = this.gfs.toFormGroup(fieldsCommande);

      const fieldsStockKey = ['uniteDeStockage', 'ratioUniteStockageUniteTechnique', 'ratioUniteStockageUniteBase', 'ratioUniteTechnique'];
      const fieldsStock = this.gfs.getFieldsByKey(fieldsStockKey, this.fields);
      this.formStock = this.gfs.toFormGroup(fieldsStock);

      this.form = new FormGroup({
        formArticle: this.formArticle,
        formFacturation: this.formFacturation,
        formCommande: this.formCommande,
        formFournisseur: this.formFournisseur,
        formStock: this.formStock
      });

      this.groups = [
        {
          titlePanel: 'Article',
          fields: fieldsArticle,
          form: this.formArticle,
          titleIcon: 'fas fa-apple-alt',
          position: GROUP_POSITION.COL1_AND_COL2,
          colsOrganization: {
            col1: ['codeApi', 'produitArticleLibelle', 'produitDeclinaison', 'actif'],
            col2: ['referenceArticle', 'produitCarne']
          }
        },
        {
          titlePanel: 'Livré par',
          fields: fieldsFournisseur,
          titleIcon: 'fas fa-truck inverse',
          form: this.formFournisseur,
          position: GROUP_POSITION.COL1,
          colsOrganization: {
            col1: fieldsFournisseurKey
          }
        },
        {
          titlePanel: 'Facturation',
          fields: fieldsFacturation,
          titleIcon: 'fas fa-euro-sign',
          form: this.formFacturation,
          position: GROUP_POSITION.COL2,
          colsOrganization: {
            col2: ['uniteDeFacturation', 'ratioUniteFacturationUniteBase', this.getKeyFormPrice(catalogueAchat)]
          }
        },
        {
          titlePanel: 'Commande',
          fields: fieldsCommande,
          titleIcon: 'fas fa-cube',
          form: this.formCommande,
          position: GROUP_POSITION.COL1,
          colsOrganization: {
            col1: ['uniteDeCommande', 'ratioUniteCommandeUniteFacturation', 'ratioUniteCommandeUniteBase', 'minimumDeCommande']
          }
        },
        {
          titlePanel: 'Stock',
          fields: fieldsStock,
          titleIcon: 'fas fa-cubes',
          form: this.formStock,
          position: GROUP_POSITION.COL2,
          colsOrganization: {
            col2: fieldsStockKey
          }

        },
      ];
    });
  };

  getKeyFormPrice = (ca: CatalogueAchatDTO) => {
    let ctrlEditable: boolean = false;

    //Création d'un catalogue achat
    if (this.utils.isNullOrEmpty(ca)) {
      return 'prixSingleMode';
      //Edition d'un catalogue achat
    } else {
      ca.groupeAchatCaList.forEach(item => {
        if (item.codeGroupeAchat === 'DEFAULT') {
          ctrlEditable = true;
        }
      });
      if (ctrlEditable) {
        return 'prixSingleMode'
      } else {
        if (ca.groupeAchatCaList.length > 1) {
          return 'prixMutlipleReadMode';
        } else {
          return 'prixSingleMode';
        }
      }
    }
  };

  saveCatalogueAchat = async () => {
    // reaffecter le ratioUniteStockageUniteTechnique sur les formArticle et formCommande
    this.formArticle.controls['ratioUniteStockageUniteTechnique'].setValue(this.formStock.controls['ratioUniteStockageUniteTechnique'].value);
    this.formCommande.controls['ratioUniteStockageUniteTechnique'].setValue(this.formStock.controls['ratioUniteStockageUniteTechnique'].value);

    // Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);

    // On enregistre que si le formulaire est valide
    const isBadArray = Array.isArray(this.formFournisseur.controls['fournisseur'].value) && (!this.formFournisseur.controls['fournisseur'].value.length || !this.formFournisseur.controls['fournisseur'].value[0]);
    if (this.isCreateMode && (isBadArray || !this.formFournisseur.controls['fournisseur'].value)) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner un fournisseur`);
    } else if (this.form.valid) {

      this.catalogueAchat.produitArticle.libelle = this.formArticle.controls['produitArticleLibelle'].value;
      this.catalogueAchat.produitArticle.produitDeclinaison = this.formArticle.controls['produitDeclinaison'].value as ProduitDeclinaisonDTO;

      if (this.isCreateMode) {
        this.catalogueAchat.fournisseur = this.formFournisseur.controls['fournisseur'].value;
      } else {
        this.catalogueAchat.fournisseur = this.formFournisseur.controls['fournisseur'].value[0];
      }

      this.catalogueAchat.referenceArticle = this.formArticle.controls['referenceArticle'].value;

      const prix = parseFloat((this.formFacturation.controls['prixSingleMode'].value + '').trim().replace(',', '.'));
      this.catalogueAchat.prix = prix;
      this.catalogueAchat.prixOrigine = this.oldPrice;
      this.catalogueAchat.minimumDeCommande = this.formCommande.controls['minimumDeCommande'].value;
      this.catalogueAchat.uniteDeStockage = this.formStock.controls['uniteDeStockage'].value as UniteDeMesureDTO;
      this.catalogueAchat.ratioUniteStockageUniteBase = this.formStock.controls['ratioUniteStockageUniteBase'].value;
      this.catalogueAchat.uniteDeCommande = this.formCommande.controls['uniteDeCommande'].value as UniteDeMesureDTO;
      this.catalogueAchat.ratioUniteCommandeUniteBase = this.formCommande.controls['ratioUniteCommandeUniteBase'].value;
      this.catalogueAchat.uniteDeFacturation = this.formFacturation.controls['uniteDeFacturation'].value as UniteDeMesureDTO;
      this.catalogueAchat.ratioUniteFacturationUniteBase = this.formFacturation.controls['ratioUniteFacturationUniteBase'].value;
      this.catalogueAchat.produitCarne = this.formArticle.controls['produitCarne'].value;
      this.catalogueAchat.actif = this.formArticle.controls['actif'].value;

      if (this.utils.isNullOrEmpty(this.catalogueAchat.actif)) {
        this.catalogueAchat.actif = false;
      }

      this.catalogueAchatSvc.saveCatalogueAchat(this.catalogueAchat, false).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {

          this.catalogueAchat = response.one;
          this.cads.catalogueAchat = this.catalogueAchat;
          this.cads.isNew = false;

          // on notifie les abonnés que l'article a été sauvegardé
          this.catalogueAchatSvc.announceSavedDTO(response);
          this.catalogueAchatSvc.announceCloseDialogArticle(null);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement de l'article "${this.catalogueAchat.produitArticle.libelle.toUpperCase()}" effectué. Vous pouvez (si vous le voulez) renseigner les appellations et les allergènes.`);
        }
      });
    }
  };

  openDeleteDialog = async (catalogueAchat: CatalogueAchatDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer l'article ${catalogueAchat.produitArticle.libelle} du fournisseur ${catalogueAchat.fournisseur.libelle} ?`,
      'Suppression d\'article');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.catalogueAchatSvc.deleteCatalogueAchat(catalogueAchat.id)
        .subscribe((response: ResponseWrapper<IsDeletableObject>) => {
          this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
        });
    }
  };

  closeDialog = () => {
    this.catalogueAchatSvc.announceCloseDialogArticle(undefined);

  };

  isVisiBleDeleteBtn = () => {
    if (this.utils.isNullOrEmpty(this.catalogueAchat)) {
      return false;
    } else {
      if (this.catalogueAchat.id > 0) {
        return this.utils.isNullOrEmpty(this.catalogueAchat.fournisseurOwnerLibelle);
      } else {
        return false;
      }
    }
  };

}
