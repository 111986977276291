import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UnitesDeMesuresService} from '../../core/services/entities/unites-de-mesures.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {UniteDeMesureDTO} from "../../core/dtos/unitedemesure-dto";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {saveAs} from "file-saver";
import {confirm} from "devextreme/ui/dialog";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {ToastService} from "../../core/services/technique/toast.service";
import {OverlayPanel} from "primeng/overlaypanel";

@Component({
  selector: 'yo-unites-de-mesures',
  templateUrl: './unites-de-mesures.component.html',
  styleUrls: ['./unites-de-mesures.component.scss']
})
export class UnitesDeMesuresComponent implements OnInit, OnDestroy {

  unitesMesure: UniteDeMesureDTO[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/unite-mesure/unite-mesure';

  selectedRows: number[] = [];

  subGraphQL: Subscription;

  subXls: Subscription;

  subImport: Subscription;

  subUniteMesureSaved: Subscription;

  errors: any[] = [];

  displayDeletePopupErrors: boolean = false;

  displayImportPopupErrors: boolean = false;

  @ViewChild('opImportXls') opImportXls: OverlayPanel;

  constructor(public readonly uniteDeMesureSvc : UnitesDeMesuresService,
              private readonly graphqlSvc: GraphQLService,
              private readonly i8nSvc: InternationalizationService,
              private utilsSvc: UtilsService,
              private readonly toastSvc: ToastService) { }

  ngOnInit() {
    this.fetchData();
    this.subscribeUniteMesureSaved();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subGraphQL);
    this.utilsSvc.unsubscribe(this.subXls);
    this.utilsSvc.unsubscribe(this.subImport);
    this.utilsSvc.unsubscribe(this.subUniteMesureSaved);
  }

  fetchData = (): void => {
    this.subGraphQL = this.graphqlSvc.sendQuery(`
      {
          allUnitesDeMesure {
              id,
              libelle,
              site { id, libelle },
              code,
              type,
              actif,
              abreviation
          }
      }
    `)
      .subscribe((res) => {
        this.unitesMesure = res.allUnitesDeMesure;
      });
  }

  subscribeUniteMesureSaved = (): void => {
    this.subUniteMesureSaved = this.uniteDeMesureSvc.uniteMesureSaved$
      .subscribe(() => this.fetchData());
  }

  openDialogEdition = (uniteMesure?: UniteDeMesureDTO): void => {
    this.uniteDeMesureSvc.announceOpeningEditionDialog(uniteMesure);
  }

  exportXslx = (): void => {
    this.subXls = this.uniteDeMesureSvc.export(this.selectedRows)
      .subscribe(response => {
        const filename = `unites-mesure-export-${new Date().getTime()}.xlsx`;
        const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        this.selectedRows = [];
        saveAs(blob, filename);
      });
  }

  canDelete = (): boolean => this.selectedRows?.length > 0;

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.uniteDeMesureSvc.delete(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res?.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const labelsError: any = this.i8nSvc.getLabelFromCode(error.code, null);
            const infosLine: string = labelsError.replace('{}', error.args);
            this.errors.push({infosLine});
            const elementToKeep = this.unitesMesure.find(u => u.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, idsToDeleteInTable.length > 1 ? `Les unités de mesure ont bien été supprimées` : `L'unité de mesure a bien été supprimée`);
        }
        this.unitesMesure = this.unitesMesure.filter(uniteMesure => !idsToDeleteInTable.find(id => id === uniteMesure.id));
      }
    });
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
    this.displayImportPopupErrors = false;
  }

  importXslx = ($event: any): void => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.opImportXls.hide();
      this.subImport = this.uniteDeMesureSvc.importFromXlsx(file)
        .subscribe((response) => {
          const res: any = response.one;
          if (res.allElementsImported) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          } else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }

          for (const item of res.elementsImported) {
            let index = this.unitesMesure.findIndex(atelier => atelier.id == item.id);
            if (index >= 0)
              this.unitesMesure[index] = item;
            else
              this.unitesMesure.push(item)
          }

          if (this.errors?.length)
            this.displayImportErrors();
        });
    }
  }

  calculateSiteValue = (uniteMesure: UniteDeMesureDTO): string => uniteMesure?.site?.libelle;

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

}
