import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitArticleDTO} from '../../../core/dtos/produit-article-dto';
import {ActivatedRoute} from '@angular/router';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {Subscription} from 'rxjs';
import {ModelViewMatierePremiere} from './model-view-matiere-premiere';
import {StockDTO} from '../../../core/dtos/stock-dto';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ToastService} from "../../../core/services/technique/toast.service";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import DataSource from "devextreme/data/data_source";

@Component({
  selector: 'yo-matieres-premieres',
  templateUrl: './matieres-premieres.component.html',
  styleUrls: ['./matieres-premieres.component.scss']
})
export class MatieresPremieresComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subResultSearchStocks: Subscription;
  subLoadStocks:Subscription;
  subRefreshStocks: Subscription;

  dataSource: DataSource;
  articles: ProduitArticleDTO[] = [];
  matieresPremieres: ModelViewMatierePremiere[] = [];
  selectedMatierePremiere: ModelViewMatierePremiere;
  totalDenrees = 0;
  stocks:StockDTO[]=[];

  // criteres de la recherche du panneau gauche
  filters : any;

  feature: string;

  stocksAnnounced: boolean = false;

  page: any;
  pageSize: any;

  constructor(public utils: UtilsService,
              private stocksSvc: StocksService,
              private produitDeclinaisonSvc:ProduitDeclinaisonService,
              private route: ActivatedRoute,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subscritionRouteData();
    // s'abonner à la recherche des articles dans le stock
    this.subscritionResultSearchStocks();
    // declencher la recuperation des lignes de stock d'une matiere premiere
    this.subscriptionLoadStocks();
    this.subscriptionRefreshMatieresPremieresAndStocks();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchStocks);
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subLoadStocks);
    this.utils.unsubscribe(this.subRefreshStocks);
  }

  subscritionResultSearchStocks = () => {
    this.subResultSearchStocks = this.produitDeclinaisonSvc.resultSearchStocksForMatieresPremieres$
      .subscribe(response => {
        this.stocks = [];
        this.matieresPremieres = response.resultList;
        this.totalDenrees = response.additionalProperties['totalDenrees'];
        this.filters = response.additionalProperties['filters'];
        this.page = this.filters.page ? this.filters.page : 1;
        this.pageSize = this.filters.size ? this.filters.size : 50;
        this.stocksAnnounced = false;

        // recharger les lignes de lot si une matiere premiere est selectionnée et toujours visible
        this.selectedMatierePremiere = this.utils.preSelectSingleList(this.matieresPremieres, this.selectedMatierePremiere);

        if (!this.utils.isNullOrEmpty(this.selectedMatierePremiere)) {
          this.stocksSvc.announceLoadStocks(this.selectedMatierePremiere)
        }
      });
  };

  subscritionRouteData = () => {
    this.subRoute = this.route.url.subscribe(response => {
      this.feature = response[0].path;
      this.stocksSvc.announceFeature(this.feature, this.page, this.pageSize);
    });
  };

  subscriptionLoadStocks = () => {
    this.stocksSvc.loadStocks$.subscribe(selectedMatierePremiere => {
      this.stocks = selectedMatierePremiere.children;
      this.selectedMatierePremiere = selectedMatierePremiere;
    });
  };

  subscriptionRefreshMatieresPremieresAndStocks = () => {
    this.subRefreshStocks = this.stocksSvc.loadMatieresPremieresAndStocks$
      .subscribe(() => {
        this.stocksSvc.announceFeature(this.feature, this.page, this.pageSize);
      });
  }

  onRowSelect = ($event: any) => {
    if($event.rowType === 'data') {
      this.stocks = [];
      if ($event.data && !$event.data.noStock) {
        this.stocksAnnounced = true;
        this.stocksSvc.announceLoadStocks($event.data);
      }
    }
  };

  openDialogPerimes = () => {
    if(this.utils.isNullOrEmpty(this.filters) || this.utils.isNullOrEmpty(this.filters['datePeremption'] )){
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Le champ de recherche "Périmé le" doit être renseigné pour activer la suppression des matières premières périmées`);
    }else{
      let lotsArticlesLoaded = this.matieresPremieres.reduce((results, item) => {
        item.children.forEach(child => results.push(child.lotArticle));
        return results;
        },
        []);
      this.stocksSvc.announceOpenDialogPerimes(lotsArticlesLoaded, this.selectedMatierePremiere);
    }
  };

  openDialogAjoutLot = (selectedMatierePremiere : ModelViewMatierePremiere, $event: any) => {
    $event.stopPropagation();
    this.selectedMatierePremiere = selectedMatierePremiere;
    this.produitDeclinaisonSvc.announceOpenDialogAjoutLot(this.selectedMatierePremiere, null);
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      if (event.data?.noStock) {
        event.rowElement.style.backgroundColor = '#ffdbcd';
        event.rowElement.style.fontWeight = 'bold';
      } else {
        event.rowElement.style.backgroundColor = '#a8e8ad';
        event.rowElement.style.fontWeight = 'bold';
        event.rowElement.style.cursor = 'pointer';
      }
    }
  };

  handlePageChange = event => {
    this.stocksAnnounced = false;
    this.stocks = [];
    this.page = event;
    this.produitDeclinaisonSvc.announcePaginationSearchStock({page: this.page - 1, size: this.pageSize});
  };

  updatePageSize = async () => {
    this.produitDeclinaisonSvc.announcePaginationSearchStock({page: this.page - 1, size: this.pageSize});
  }
}
