// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.6-rc-9834(3fdd79f372)-C30/11/2023-17:02:58-B30/11/2023-17:09:10' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.6-rc-9834(3fdd79f372)-C30/11/2023-17:02:58-B30/11/2023-17:09:10",
  branch: "master",
  latestTag: "5.6-rc",
  revCount: "9834",
  shortHash: "3fdd79f372",
  longHash: "3fdd79f3729830f83d6355d1ae5c8f3a19d3372d",
  dateCommit: "30/11/2023-17:02:58",
  dateBuild: "30/11/2023-17:09:10",
  buildType: "Ansible",
  } ;
