import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import CustomStore from "devextreme/data/custom_store";
import {Subscription} from "rxjs";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {UniteDeMesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";
import {SearchSupplierWrapper} from "../../../../../core/suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../../../../core/suppliers/search-supplier";
import {DevextremeService, FilterItem} from "../../../../../core/services/technique/devextreme.service";
import {SiteDTO} from "../../../../../core/dtos/site-dto";
import {TacheDTO} from "../../../../../core/dtos/tache-dto";
import {DxDataGridComponent} from "devextreme-angular";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {PointDeLivraisonService} from "../../../../../core/services/entities/point-de-livraison.service";
import {PointDeLivraisonDTO} from "../../../../../core/dtos/point-de-livraison-d-t-o";
import ModeleConditionnementPlcDTO from "../../../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {ClientsService} from "../../../../../core/services/entities/clients.service";
import {ClientDTO} from "../../../../../core/dtos/client-dto";
import {ClientsBusinessService} from "../../../../../core/services/gestion-clients/clients.service";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {ContratMenuService} from "../../../../../core/services/gestioncontrats/contrat-menu.service";
import {ContratmenuDTO} from "../../../../../core/dtos/contratmenu-dto";
import {ConviveDTO} from "../../../../../core/dtos/convive-dto";
import {GraphQLService} from "../../../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-dialog-ajout-mcplc-plc',
  templateUrl: './dialog-ajout-mcplc-plc.component.html',
  styleUrls: ['./dialog-ajout-mcplc-plc.component.scss']
})
export class DialogAjoutMcplcPlcComponent implements OnInit, OnDestroy {

  plcList: PointDeLivraisonDTO[] = [];
  allClients: ClientDTO[] = [];
  uniteAConditionner: UniteDeMesureDTO;
  declinaisons: DeclinaisonDTO[] = [];
  sitesUtilisateurConnecte: SiteDTO[] = [];
  allPlcs: PointDeLivraisonDTO[] = [];
  allTaches: TacheDTO[] = [];
  allOffres: ContratmenuDTO[] = [];
  allPrestations: ConviveDTO[] = [];

  dataSource: CustomStore;

  subOpenDialog: Subscription;
  subClients: Subscription;
  subPlcs: Subscription;
  subTaches: Subscription;
  subRegimes: Subscription;
  subOffres: Subscription;
  subPrestations: Subscription;

  openDialog = false;
  hasIDistri = false;
  dialogTitle = 'Liaison du modèle avec des points de livraison client';

  model: ModeleConditionnementPlcDTO;

  allMode: string;
  checkBoxesMode: string;

  @ViewChild("gridPlcsToAdd") grid: DxDataGridComponent;
  clientIdList: number[] = [];
  clientIdListFromCode: number[] = [];
  plcIdList: number[] = [];
  plcIdListFromCode: number[] = [];
  siteIdList: number[] = [];
  idsOffreAlimentaire: number[] = [];
  idsPrestations: number[] = [];

  isActionDisabled: boolean = true;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private plcSvc: PointDeLivraisonService,
              private clientSvc: ClientsService,
              private clientsBusinessSvc: ClientsBusinessService,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService,
              private offreAlimSvc: ContratMenuService,
              private graphqlSvc: GraphQLService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subClients);
    this.utils.unsubscribe(this.subPlcs);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subTaches);
    this.utils.unsubscribe(this.subRegimes);
    this.utils.unsubscribe(this.subOffres);
    this.utils.unsubscribe(this.subPrestations);
  }

  ngOnInit(): void {
    this.initOpenDialogSubscription();
    this.initHasGestionIDistri();
    this.loadFilterClientsSubscription();
    this.loadFilterPlcSubscription();
    this.loadFilterSites();
    this.loadFilterOffresAlimentairesSubscription();
    this.loadFilterPrestationsSubscription();
    this.dataSource = this.initCustomStore();
  }

  initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.plcSvc.openDialogBindPlcsWithMcPlc$.subscribe(model => {
      this.openDialog = true;
      this.model = model;
    });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  closeDialog = (): void => {
    this.openDialog = false;
  };

  loadFilterOffresAlimentairesSubscription = (): void => {
    this.subOffres = this.gds.getAll(this.offreAlimSvc.getEntityName(), this.offreAlimSvc.getSort(), true).subscribe(response => {
      this.allOffres = response.resultList;
    });
  }

  loadFilterPrestationsSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subPrestations = this.graphqlSvc.sendQuery(`
      {
          allPrestations(filters: {
          siteIds: [${idsSites}]
        }) {
              id,
              libelle,
              site { id, libelle },
              code,
              actif
          }
      }
    `).subscribe(response => {
      this.allPrestations = response.allPrestations;
    });
  }

  loadFilterClientsSubscription = (): void => {
    this.subClients = this.gds.getAll(this.clientSvc.getEntityName(), this.clientSvc.getSort(), true).subscribe(response => {
      this.allClients = response.resultList;
    });
  };

  loadFilterPlcSubscription = (): void => {
    this.subPlcs = this.plcSvc.getPlcList().subscribe(response => {
      this.allPlcs = response.resultList;
    });
  };

  loadFilterSites = (): void => {
    this.sitesUtilisateurConnecte = this.auth2Svc.utilisateur.sites;
  };

  onChangeClientFilter = ($event): void => {
    this.clientIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeClientCodeFilter = ($event): void => {
    this.clientIdListFromCode = $event.value;
    this.grid.instance.refresh();
  };

  onChangePlcsFilter = ($event): void => {
    this.plcIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeCodeFilter = ($event): void => {
    this.plcIdListFromCode = $event.value;
    this.grid.instance.refresh();
  };

  onChangeSiteFilter = ($event): void => {
    this.siteIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeOffreAlimentaireFilter = ($event): void => {
    this.idsOffreAlimentaire = $event.value;
    this.grid.instance.refresh();
  }

  onChangePrestationFilter = ($event): void => {
    this.idsPrestations = $event.value;
    this.grid.instance.refresh();
  }

  convertNumber = (value): number => parseInt(value, 10);

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): CustomStore => new CustomStore({
    key: 'id',
    load: async (loadOptions: any) => {
      const sitesIds = this.siteIdList.length ? this.siteIdList : this.auth2Svc.utilisateur.sites.map(item => item.id);

      let pageSize: number = loadOptions.take || this.grid.instance.pageSize();
      let page: number = this.grid.instance.pageIndex() + 1;
      const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

      const plcIds: Set<number> = new Set<number>(this.plcIdListFromCode);
      this.plcIdList.forEach(id => plcIds.add(id));

      const paramPlcIds = [...plcIds];

      const clientsIds: Set<number> = new Set<number>(this.clientIdListFromCode);
      this.clientIdList.forEach(id => clientsIds.add(id));

      const paramClientIds = [...clientsIds];

      const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();

      ssw.filtersMap['ids'] = new SearchSupplier(undefined, paramPlcIds);
      ssw.filtersMap['idsClient'] = new SearchSupplier(undefined, paramClientIds);
      ssw.filtersMap['sites'] = new SearchSupplier(undefined, sitesIds);
      ssw.filtersMap['idsOffres'] = new SearchSupplier(undefined, this.idsOffreAlimentaire);
      ssw.filtersMap['idsPrestations'] = new SearchSupplier(undefined, this.idsPrestations);
      ssw.filtersMap['withoutModeleConditionnementPlc'] = new SearchSupplier(true, undefined);

      if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
        // Si je coche tout => Il faut omettre la pagination
        page = null;
        pageSize = null;
      }

      return this.plcSvc.getPlcWithoutMcPlcList(page, pageSize, ssw)
        .toPromise()
        .then(response => {
          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;
          return {data: response.resultList, totalCount: response.totalElements};
        });
    }
  });

  canSave = (): boolean => this.hasIDistri;

  save: () => Promise<void> = async () => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    if(!rowsSelected.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner au minimum un point de livraison client à ajouter`);
    } else {
      this.clientsBusinessSvc.saveMultiplePlc(this.model.id, rowsSelected)
        .subscribe(response => {
          this.clientsBusinessSvc.announceBindPlcsDone();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Point(s) de livraison client associé(s) avec succès`);
          this.closeDialog();
        });
    }
  };

}
