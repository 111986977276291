import {Component, OnInit, ViewChild} from '@angular/core';
import {RepasService} from '../../core/services/entities/repas.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {RepasDTO} from "../../core/dtos/repas-dto";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {saveAs} from "file-saver";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {confirm} from "devextreme/ui/dialog";
import {OverlayPanel} from "primeng/overlaypanel";
import {Auth2Service} from "../../core/services/security/auth2.service";

@Component({
  selector: 'yo-repas',
  templateUrl: './repas.component.html',
  styleUrls: ['./repas.component.scss']
})
export class RepasComponent implements OnInit {

  @ViewChild('opImportXls') opImportXls: OverlayPanel;

  repas: RepasDTO[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/repas/repas';

  selectedRows: number[] = [];

  subGraphQL: Subscription;

  subImport: Subscription;

  subXls: Subscription;

  subMealSaved: Subscription;

  errors: any[] = [];

  displayDeletePopupErrors: boolean = false;

  displayImportPopupErrors: boolean = false;

  constructor(public repasSvc:RepasService,
              private readonly graphqlSvc: GraphQLService,
              private utilsSvc: UtilsService,
              private readonly i8nSvc: InternationalizationService,
              private readonly toastSvc: ToastService,
              private auth2Svc: Auth2Service) { }

  ngOnInit() {
    this.fetchData();
    this.subscribeMealSaved();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subGraphQL);
    this.utilsSvc.unsubscribe(this.subImport);
    this.utilsSvc.unsubscribe(this.subXls);
    this.utilsSvc.unsubscribe(this.subMealSaved);
  }

  fetchData = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subGraphQL = this.graphqlSvc.sendQuery(`
      {
          allRepas(filters: {
          siteIds: [${idsSites}]
        }) {
              id,
              libelle,
              site { id, libelle },
              code,
              ordre,
              actif,
          }
      }
    `)
      .subscribe((res) => {
        this.repas = res.allRepas;
      });
  }

  openDialogEdition = (repas?: RepasDTO): void => {
    this.repasSvc.announceOpeningEditionDialog(repas);
  }

  exportXslx = (): void => {
    this.subXls = this.repasSvc.export(this.selectedRows)
      .subscribe(response => {
        const filename = `repas-export-${new Date().getTime()}.xlsx`;
        const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        this.selectedRows = [];
        saveAs(blob, filename);
      });
  }

  canDelete = (): boolean => this.selectedRows?.length > 0;

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.repasSvc.delete(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res?.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const labelsError: any = this.i8nSvc.getLabelFromCode(error.code, null);
            const infosLine: string = labelsError.replace('{}', error.args);
            this.errors.push({infosLine});
            const elementToKeep = this.repas.find(u => u.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, idsToDeleteInTable.length > 1 ? `Les repas ont bien été supprimés` : `Le repas a bien été supprimé`);
        }
        this.repas = this.repas.filter(repas => !idsToDeleteInTable.find(id => id === repas.id));
      }
    });
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
    this.displayImportPopupErrors = false;
  }

  importXslx = ($event: any): void => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.opImportXls.hide();
      this.subImport = this.repasSvc.importFromXlsx(file)
        .subscribe((response) => {
          const res: any = response.one;
          if (res.allElementsImported) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          } else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }

          for (const item of res.elementsImported) {
            let index = this.repas.findIndex(atelier => atelier.id == item.id);
            if (index >= 0)
              this.repas[index] = item;
            else
              this.repas.push(item)
          }

          if (this.errors?.length)
            this.displayImportErrors();
        });
    }
  }

  displayImportErrors = (): void => { this.displayImportPopupErrors = true; }

  calculateSiteValue = (repas: RepasDTO): string => repas?.site?.libelle;

  subscribeMealSaved = (): void => {
    this.subMealSaved = this.repasSvc.mealSaved$
      .subscribe(() => this.fetchData());
  }

}
